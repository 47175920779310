<template>
    <a-modal
            :title="id?'修改角色':'新增角色'"
            :visible="visible"
            width="35%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="角色名称" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入角色名称"
                        v-decorator="['roleName',
            {rules: [{ required: true,max:50, message: '角色名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="权限分配" v-bind="formItemLayout">
                <a-tree
                        v-decorator="['permissions',
            {rules: [{ required: true,message: '权限分配必选'}]}]"
                        checkable
                        defaultExpandAll
                        :expanded-keys="expandedKeys"
                        v-model="selectedKeys"
                        :tree-data="treeData"
                        :replace-fields="replaceFields"
                        @expand="onExpand"
                        @check="onCheck"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {add,update,auth,detail} from '@/api/role'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                maxLength:1000,
                confirmLoading: false,
                replaceFields:{
                    title: 'permissionName',
                    key:'id'
                },
                treeData:[],
                expandedKeys: [],
                autoExpandParent: true,
                checkedKeys: [],
                selectedKeys: [],
                id:'',
              list:[]
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                    this.loadAuth()
                }
            }
        },
        methods:{
            loadAuth() {
                auth().then((res)=>{
                  this.treeData= res
                })
            },
            onCheck(selectedKeys, info) {
              console.log(selectedKeys)
              var arry =[]
              arry = selectedKeys
              this.list = [...arry]
              if(info.halfCheckedKeys.length>0) {
                if(!selectedKeys.includes(info.halfCheckedKeys[0])){
                  this.list.push(info.halfCheckedKeys[0])
                }
              }
              console.log(this.list)
                this.form.setFieldsValue({
                    permissions:  selectedKeys
                });
            },
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
            },
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "id") {
                        this.getInfo(record[key])
                        this.id = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            getInfo(id) {
                detail({id:id}).then((res)=>{
                   var permissions = res.permissions.map((item)=>{return item.id})
                    console.log(permissions)
                    this.selectedKeys = permissions
                    this.form.setFieldsValue({
                        permissions:permissions
                    })
                })
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                        console.log(values)
                        if (!this.id) {
                          values.permissions = this.list
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                            values.permissions = this.list
                            update(values).then((res) => {
                                this.departmentSn = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.id=''
                this.selectedKeys =[]
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

</style>
const columns = [
        {
            title: '#',
            scopedSlots: { customRender: 'serial' }
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '权限明细',
            dataIndex: 'permissions',
            key: 'permissions',
            scopedSlots: { customRender: 'permissions' },
        },
        {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
export {
    columns
}

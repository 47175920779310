<template>
    <div class="container">
        <a-row>
            <a-form :layout="formLayout">
                <a-form-item
                        label="角色名称"
                >
                    <a-input placeholder="请输入" v-model="searchForm.roleName"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="search">查询</a-button>
                    <a-button style="margin-left: 20px" @click="reset()">重置</a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row style="padding: 12px 0 0 12px">
            <a-button type="primary" @click="addOrEdite(null)">新增</a-button>
        </a-row>
        <br/>
        <a-table
                 bordered
                 rowKey="id"
                 :columns="columns"
                 :pagination="false"
                 :loading="loading"
                 :dataSource="dataList">
            <span slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
            </span>
            <span slot="permissions" slot-scope="text,record">
                   <a class="txt-danger" href="javascript:;" @click="saw(record)">查看</a>
            </span>
            <template slot="action" slot-scope="text">
                <a @click="addOrEdite(text)" v-if="text.classification!==0">编辑</a>
                <a-divider type="vertical" v-if="text.classification!==0"/>
                <a-popconfirm
                        title="是否同意删除？"
                        @confirm="remove(text.id)"
                        okText="是"
                        cancelText="否"
                        placement="topRight"
                >
                    <a class="txt-danger" href="javascript:;" v-if="text.classification!==0">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
        <br/>
        <form-modal
                ref="FormModal"
                :visible="visible"
                @close="handcanle"
                @confirm="handleConfirm"
        />
      <info-modal
          ref="InfoModal"
          :visible="infovisible"
          @close="infocanle"
      />
    </div>
</template>

<script>
    import {columns} from "./module";
    import FormModal from "./module/formModal"
    import InfoModal from "./module/infoModal";
    import {list,del,detail} from '@/api/role'
    export default {
        components:{FormModal,InfoModal},
        data(){
            return {
                visible: false,
                infovisible: false,
                columns,
                formLayout:'inline',
                loading: false,
                searchForm:{},
                pagination: {},
                dataList:[]
            }
        },
        created() {
            this.loadList()
        },
        methods:{
            search() {
                this.loadList()
            },
            reset(){
              this.searchForm ={}
              this.loadList()
            },
            loadList() {
                const { page = 1, pageSize = -1 } = this.pagination
                const current = page
                const params = {
                    ...this.searchForm,
                    current,
                    size: pageSize
                }
                list(params).then((res)=>{
                    this.dataList = res.records
                    const pagination = { ...this.pagination }
                    pagination.total = Number(res.total)
                    pagination.pageSize = Number(10)
                    this.pagination = pagination
                })
            },
            addOrEdite(row) {
                this.visible = true
                if(row){
                    this.$refs.FormModal.setFormValues(row)
                }
            },
            handcanle() {
                this.visible = false
            },
            handleConfirm() {
                this.loadList()
            },
            infocanle() {
                this.infovisible = false
            },
            saw(row){
              this.infovisible = true
              if(row){
                this.$refs.InfoModal.setFormValues(row)
              }
            },
            remove(id) {
                del({id:id}).then((res) =>{
                    this.loadList()
                })
            }
        }
    }
</script>

<style scoped>
</style>
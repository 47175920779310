<template>
  <a-modal
      title="查看角色"
      :visible="visible"
      width="30%"
      :confirm-loading="confirmLoading"
      cancel-text="取消"
      @cancel="handleCancel">
    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>
    </template>
    <a-form :form="form">
      <a-form-item label="权限分配" v-bind="formItemLayout">
        <a-tree
            v-decorator="['permissions',
            {rules: [{ required: true,message: '权限分配必选'}]}]"
            checkable
            defaultExpandAll
            v-model="selectedKeys"
            v-if="treeData.length>0"
            :tree-data="treeData"
            :replace-fields="replaceFields"
            disabled
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {auth,detail} from '@/api/role'
export default {
  props:['visible'],
  data(){
    return {
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      maxLength:1000,
      confirmLoading: false,
      replaceFields:{
        title: 'permissionName',
        key:'id'
      },
      treeData:[],
      expandedKeys: [],
      defaultExpandAll: true,
      checkedKeys: [],
      selectedKeys: [],
      id:''
    }
  },
  computed: {
    show: {
      get: function (){
        return this.visible;
      },
      set: function () {}
    }
  },
  watch:{
    visible(val){
      if(val) {
        this.loadAuth()
      }
    }
  },
  methods:{
    loadAuth() {
      auth().then((res)=>{
        this.treeData= res
      })
    },
    setFormValues(record) {
      console.log(record)
      Object.keys(record).forEach(key => {
        this.form.getFieldDecorator(key);
        let obj = {};
        if(key == "id") {
          this.getInfo(record[key])
          this.id = record[key]

        }else {
          obj[key] = record[key];
          this.form.setFieldsValue(obj);
        }
      });
    },
    getInfo(id) {
      console.log(id)
      detail({id:id}).then((res)=>{
        var permissions = res.permissions.map((item)=>{return item.id})
        this.selectedKeys = permissions
        this.form.setFieldsValue({
          permissions:permissions
        })
      })
    },
    handleCancel() {
      this.$emit('close')
      this.id=''
      this.selectedKeys =[]
      this.form.resetFields()
    }
  }
}
</script>

<style scoped>

</style>